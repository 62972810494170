const storeJson = [
  {
    id: "3456",
    img: "/images/logo/lowell-gardens.svg",
    alt: "Lowell Gardens Logo",
    logoPinch: "/images/logo/lowell-gardens.svg",
    exteriorStore: "/images/storeExterior/lowellOutdoor.jpg",
    interiorStore: "/images/storeInterior/lowellOutdoor.jpg",
    exAlt: "Lowell Gardens Store Exterior",
    inAlt: "Lowell Gardens Store Interior",
    name: "Lowell Gardens",
    address1: "3615 W 49th Ave",
    address2: "Denver, CO 80221",
    escapedUrlAddress: "3615+W+49th+Avenue+Denver+CO+80221",
    lat: 39.78572,
    lng: -105.031516,
    phone: "(720) 362-7095",
    phoneLink: "tel:+1-720-362-7095",
    type: {
      Rec: "3456",
      Med: "",
      // Consumption:'1'
    },
    hours: {
      monday: "8am - 10pm",
      tuesday: "8am - 10pm",
      wednesday: "8am - 10pm",
      thursday: "8am - 10pm",
      friday: "8am - 10pm",
      saturday: "8am - 10pm",
      sunday: "8am - 10pm",
    },
    storeDescription:
      "Located in the Berkley Neighborhood near Regis University, Lowell Gardens’ staff is here to help you find the right thc product. We have a vast selection of flower, concentrates, and edibles. Our flower is carefully picked and packaged deli style.",
    vip: "https://enrollnow.vip/login/2966?location=lowell-gardens",
    shop: "Shop",
    href: "lowellgardens",
    facebook: "https://www.facebook.com/Yuma-Way-Lowell-Gardens-Dispensary-106779174963951",
    instagram: "https://www.instagram.com/lowell_gardens/",
    twitter: "https://twitter.com/Yuma_Way",
  },
  {
    id: "1671",
    img: "/images/logo/cherry-peak.svg",
    alt: "Cherry Peak Logo",
    logoPinch: "/images/logo/cherry-peak.svg",
    exteriorStore: "/images/storeExterior/cherrypeakOutdoor.jpg",
    interiorStore: "/images/storeInterior/CherryPeak_Indoor.jpg",
    exAlt: "Cherry Peak Store Exterior",
    inAlt: "Cherry Peak Store Interior",
    name: "Cherry Peak",
    address1: "4601 E Mississippi Ave",
    address2: "Denver, CO 80246",
    escapedUrlAddress: "4601+E+Mississippi+Ave%2C+Denver%2C+CO+80246",
    lat: 39.69698,
    lng: -104.933647,
    phone: "(303) 386-3185",
    phoneLink: "tel:+1-303-386-3185",
    type: {
      Rec: "1671",
      Med: "1670",
    },
    hours: {
      monday: "8am - 11:45pm",
      tuesday: "8am - 11:45pm",
      wednesday: "8am - 11:45pm",
      thursday: "8am - 11:45pm",
      friday: "8am - 11:45pm",
      saturday: "8am - 11:45pm",
      sunday: "8am - 11:45pm",
    },
    storeDescription:
      "As Yuma Way's first Glendale location, Cherry Peak Dispensary has developed into a cozy shop offering a varied selection of flower, concentrates, edibles, and more that will impress even the most connoisseur of customers! Our staff excelsl in providing all patrons with a welcoming experience allowing them to explore and learn about all of the various products we carry. Not only does Cherry Peak have one of the largest selections in Glendale, Colorado but we also run deals regularly on the products you all have come to love, so never feel hesitant to check out what's new every week! We pride ourselves on quality products at competitive prices to ensure the best experiences every single time you visit. Come see how we do things the Yuma Way at Cherry Peak Dispensary!",
    vip: "https://enrollnow.vip/login/2966?location=cherry-peak",
    shop: "Shop",
    href: "cherrypeak",
    facebook: "https://www.facebook.com/YumaWayCherryPeakDispensary",
    instagram: "https://www.instagram.com/cherry_peak_dispensary/",
    twitter: "https://twitter.com/Yuma_Way",
  },
  {
    id: "1691",
    img: "/images/logo/twin-peaks.svg",
    alt: "Twins Peak Logo",
    logoPinch: "/images/logo/twin-peaks.svg",
    exteriorStore: "/images/storeExterior/twinpeaksOutdoor.jpg",
    interiorStore: "/images/storeInterior/TwinPeaks_Indoor.jpg",
    exAlt: "Twins Peak Store Exterior",
    inAlt: "Twins Peak Store Interior",
    name: "Twin Peaks",
    address1: "900 S Hover St Unit A",
    address2: "Longmont, CO 80501",
    escapedUrlAddress: "900+S+Hover+St%2C+Longmont%2C+CO+80501",
    lat: 40.15176,
    lng: -105.13012,
    phone: "(720) 892-5969",
    phoneLink: "tel:+1-720-892-5969",
    type: {
      Rec: "1691",
      Med: "1690",
    },
    hours: {
      monday: "8am - 9:45pm",
      tuesday: "8am - 9:45pm",
      wednesday: "8am - 9:45pm",
      thursday: "8am - 9:45pm",
      friday: "8am - 9:45pm",
      saturday: "8am - 9:45pm",
      sunday: "8am - 9:45pm",
    },
    storeDescription:
      "Located in Longmont, Colorado- a gateway to the Rocky Mountains, Twin Peaks Dispensary offers a wide range of cannabis products suitable for both locals and tourists. With a view of the mountains right from our door, we pride ourselves on helping our adventurous community each and every day. Our Twin Peaks location is managed by a multi-background team with decades of combined cannabis knowledge, come on by today!",
    vip: "https://enrollnow.vip/login/2966?location=twin-peaks",
    shop: "Shop",
    href: "twinpeaks",
    facebook: "https://www.facebook.com/TwinPeaksDispensary",
    instagram: "https://www.instagram.com/twin_peaks_dispensary/",
    twitter: "https://twitter.com/Yuma_Way",
  },
  {
    id: "1693",
    img: "/images/logo/berkeley.svg",
    alt: "Berkeley Logo",
    logoPinch: "/images/logo/berkeley.svg",
    exteriorStore: "/images/storeExterior/berkeleyOutdoor.jpg",
    interiorStore: "/images/storeInterior/Berkeley_Indoor.jpg",
    exAlt: "Berkeley Store Exterior",
    inAlt: "Berkeley Store Interior",
    name: "Berkeley",
    address1: "5398 Sheridan Blvd",
    address2: "Arvada, CO 80002",
    escapedUrlAddress: "Yuma+Way+5389+Sheridan+Blvd%2C+Arvada%2C+CO+80002",
    lat: 39.7947,
    lng: -105.05337,
    phone: "(720) 310-8057",
    phoneLink: "tel:+1-720-310-8057",
    type: {
      Rec: "1693",
      Med: "",
    },
    hours: {
      monday: "8am - 9:45pm",
      tuesday: "8am - 9:45pm",
      wednesday: "8am - 9:45pm",
      thursday: "8am - 9:45pm",
      friday: "8am - 9:45pm",
      saturday: "8am - 9:45pm",
      sunday: "8am - 9:45pm",
    },
    storeDescription:
      "Located in Old Town Arvada, our brightly colorful exterior will direct you to cannabis heaven. Our store is stocked with a variety of cannabis products and smoking accessories in every category. All our products are tested and approved by our staff. We carry everything from $18 wax to $70 cannagars; we have something for everyone, from the stoner who just needs a joint to the connoisseur smoker looking for the top of the line brands and everything in between! ",
    vip: "https://enrollnow.vip/login/2966?location=berkeley",
    shop: "Shop",
    href: "berkeleydispensary",
    facebook: "https://www.facebook.com/YumaWayBerkeleyDispensary",
    instagram: "https://www.instagram.com/berkeley_dispensary/",
    twitter: "https://twitter.com/Yuma_Way",
  },
  {
    id: "17",
    img: "/images/logo/yuma.svg",
    alt: "YumaWay Logo",
    logoPinch: "/images/logo/yuma.svg",
    exteriorStore: "/images/storeExterior/1136yumaOutdoor.jpg",
    interiorStore: "/images/storeInterior/1136Yuma_Indoor.jpg",
    exAlt: "YumaWay Store Exterior",
    inAlt: "YumaWay Store Interior",
    name: "1136 Yuma Way",
    address1: "1136 Yuma Ct",
    address2: "Denver, CO 80204",
    escapedUrlAddress: "1136+Yuma+Ct%2C+Denver%2C+CO+80204",
    lat: 39.73432,
    lng: -105.014451,
    phone: "(720) 900-1136",
    phoneLink: "tel:+1-720-900-1136",
    type: {
      Rec: "17",
      Med: "",
    },
    hours: {
      monday: "8am -11:45pm",
      tuesday: "8am - 11:45pm",
      wednesday: "8am - 11:45pm",
      thursday: "8am - 11:45pm",
      friday: "8am - 11:45pm",
      saturday: "8am - 11:45pm",
      sunday: "8am - 11:45pm",
    },
    storeDescription:
      "1136 Yuma Dispensary is located right off I-25 in Denver, Colorado. Our proximity to one of the most trafficked interstates in Denver, 1136 Yuma makes for a quick stop on your commute to or from home. Our location is convenient to those residing in the Lincoln Park neighborhood and Downtown Denver. 1136 Yuma offers ample parking to accommodate any size car/truck or any size group. Although our dispensary isn’t the largest, we encompass a large variety of all products to ensure that we can meet your every need. As a Yuma Way location, you can ensure to experience a plethora of products and deals on a daily basis. Visit us today! ",
    vip: "https://enrollnow.vip/login/2966?location=1136-yuma",
    shop: "Shop",
    href: "1136yuma",
    facebook: "https://www.facebook.com/1136YumaDispensary",
    instagram: "https://www.instagram.com/1136_yuma_dispensary/",
    twitter: "https://twitter.com/Yuma_Way",
  },
  {
    id: "1525",
    img: "/images/logo/cannacity.svg",
    alt: "Cannacity Logo",
    logoPinch: "/images/logo/cannacity.svg",
    exteriorStore: "/images/storeExterior/cannacityOutdoor.jpg",
    interiorStore: "/images/storeInterior/cannacityIndoor.jpg",
    exAlt: "Cannacity Store Exterior",
    inAlt: "Cannacity Store Interior",
    name: "Canna City",
    address1: "7150 Eudora Dr",
    address2: "Commerce City, CO 80022",
    escapedUrlAddress: "7150+Eudora+Drive,+Commerce+City,+CO+80022",
    lat: 39.82587,
    lng: -104.92745,
    phone: "(720) 336-8689",
    phoneLink: "tel:+1-720-336-8689",
    type: {
      Rec: "1525",
      Med: "",
    },
    hours: {
      monday: "8am - 9:45pm",
      tuesday: "8am - 9:45pm",
      wednesday: "8am - 9:45pm",
      thursday: "8am - 9:45pm",
      friday: "8am - 9:45pm",
      saturday: "8am - 9:45pm",
      sunday: "8am - 9:45pm",
    },
    storeDescription:
      "A dispensary managed by locals, Canna City serves the surrounding neighborhoods in Commerce City. Despite the local feel, Canna City is a great stop for commuters as it is located off Highway 85. Similar to Yuma Way’s other locations, Canna City carries a wide selection of THC edibles, concentrates, topicals, and cannabis flower. Anything from high exposure brands like Snaxland, Cookies, GKUA to regional favorite products from N-Fuzed, Incredibles, Koala Bars can be found on the shelves. Visit us today and explore all our options! ",
    vip: "https://enrollnow.vip/login/2966?location=canna-city",
    shop: "Shop",
    href: "cannacity",
    facebook: "https://www.facebook.com/YumaWayCannaCityDispensary",
    instagram: "https://www.instagram.com/canna_city_dispensary/",
    twitter: "https://twitter.com/Yuma_Way",
  },
  {
    id: "1",
    img: "/images/logo/coffeeJointLogo.png",
    alt: "Coffee Joint Logo",
    logoPinch: "/images/logo/coffeeJointLogo.png",
    exteriorStore: "/images/storeExterior/1136yumaOutdoor.jpg",
    interiorStore: "/images/storeInterior/TCJinside.jpg",
    exAlt: "Coffee Joint Store Exterior",
    inAlt: "Coffee Joint Store Interior",
    name: "The Coffee Joint",
    address1: "1130 Yuma Ct",
    address2: "Denver, CO 80204",
    escapedUrlAddress: "1130+Yuma+Ct%2C+Denver%2C+CO+80204",
    lat: 39.734329,
    lng: -105.014459,
    phone: "(720) 583-4657",
    phoneLink: "tel:+1-720-583-4657",
    type: {
      Rec: "",
      Med: "",
      Consumption: "1",
    },
    hours: {
      monday: "8am - 9:45pm",
      tuesday: "8am - 9:45pm",
      wednesday: "8am - 9:45pm",
      thursday: "8am - 9:45pm",
      friday: "8am - 9:45pm",
      saturday: "8am - 9:45pm",
      sunday: "8am - 9:45pm",
    },
    storeDescription:
      "A pioneer in Colorado’s cannabis industry, The Coffee Joint opened in 2018 to acclaim from local and national media. When in operation, the lounge allows e-nail dabbing, edible consumption and vaporizing of flower/concentrates to take place throughout the establishment. From jam sessions, intimate concerts, painting, cannabis vendor showcases, table games, book readings; The Coffee Joint is a hot spot for local wellness, education, and entertainment events. The Coffee Joint’s Consumption Lounge is currently closed to ensure the wellbeing of staff and retail patrons. Smoking accessories and snacks are available for purchase in-store. CBD products can be purchased at our brick-and-mortar and are also available for purchase online! ",
    vip: "",
    shop: "Visit",
    href: "coffeejoint",
    facebook: "https://www.facebook.com/CannabayDispensary",
    instagram: "https://www.instagram.com/canna_bay_dispensary/",
    twitter: "https://twitter.com/Yuma_Way",
  },

  {
    id: "0000",
    img: "/images/logo/stadiumGardens.svg",
    alt: "YumaWay Logo",
    logoPinch: "/images/logo/stadiumGardens.svg",
    exteriorStore: "",
    interiorStore: "",
    exAlt: "YumaWay Store Exterior",
    inAlt: "YumaWay Store Interior",
    name: "5441 Quebec",
    address1: "5441 Quebec St",
    address2: "Commerce City, CO 80022",
    escapedUrlAddress: "5441+Quebec+St,+Commerce+City,+CO+80022",
    lat: 39.7973775,
    lng: -104.9059831,
    phone: "(720) 465-5449",
    phoneLink: "tel:+1-720-465-5449",
    type: {
      Rec: "4652",
      Med: "",
    },
    hours: {
      monday: "8am - 9:45pm",
      tuesday: "8am - 9:45pm",
      wednesday: "8am - 9:45pm",
      thursday: "8am - 9:45pm",
      friday: "8am - 9:45pm",
      saturday: "8am - 9:45pm",
      sunday: "8am - 9:45pm",
    },
    storeDescription:
      "Beautiful brand new dispensary in brand new building. Located in Commerce City, Stadium Gardens is easily accessible from I-70 via Quebec St, and convenient to shoppers coming from Denver International Airport as well as those who are near Thornton. As a member of Yuma Way we offer a wide variety of marijuana flower strains, concentrates and extracts, vape pens, and edibles at affordable prices. We also offer the latest products like Wyld Gummies and year long favorites like The Clear, Evolab, and Cheeba Chews. Our friendly and knowledgeable staff will assist you with product selection and answer your questions.",
    vip: "https://enrollnow.vip/login/2966?location=stadium-gardens",
    shop: "Shop",
    href: "stadiumgardens",
    facebook: "",
    instagram: "",
    twitter: "",
  },
  {
    id: "5084",
    img: "/images/logo/parkHills.svg",
    alt: "Park Hill Gardens Logo",
    logoPinch: "/images/logo/parkHills.svg",
    exteriorStore: "",
    interiorStore: "",
    exAlt: "Park Hill Gardens Store Exterior",
    inAlt: "Park Hill Gardens Store Interior",
    name: "",
    address1: "6901 E Colfax Ave",
    address2: "Denver, CO 80220",
    escapedUrlAddress: "6901+E+Colfax+Ave,+Denver,+CO+80220",
    lat: 39.7403009,
    lng: -104.9079883,
    phone: "(720) 836-7275",
    phoneLink: "tel:+1-720-836-7275",
    type: {
      Rec: "5084",
      Med: "",
    },
    hours: {
      monday: "8am - 11:45pm",
      tuesday: "8am - 11:45pm",
      wednesday: "8am - 11:45pm",
      thursday: "8am - 11:45pm",
      friday: "8am - 11:45pm",
      saturday: "8am - 11:45pm",
      sunday: "8am - 11:45pm",
    },
    storeDescription: "Coming Soon",
    vip: "",
    shop: "Shop",
    href: "parkhillgardens",
    facebook: "",
    instagram: "",
    twitter: "",
  },
  {
    id: "4440",
    img: "/images/logo/morrison.svg",
    alt: "Morrison Gardens Logo",
    logoPinch: "/images/logo/morrison.svg",
    exteriorStore: "",
    interiorStore: "",
    exAlt: "Morrison Gardens Store Exterior",
    inAlt: "Morrison Gardens Store Interior",
    name: "Morrison Gardens",
    address1: "4440 Morrison Rd",
    address2: "Denver, CO 80219",
    escapedUrlAddress: "4440+Morrison+Rd,+Denver,+CO+80219",
    lat: 39.7016652,
    lng: -105.0457904,
    phone: "",
    phoneLink: "",
    type: {
      Rec: "5416",
      Med: "",
    },
    hours: {
      monday: "8am - 12am",
      tuesday: "8am - 12am",
      wednesday: "8am - 12am",
      thursday: "8am - 12am",
      friday: "8am - 12am",
      saturday: "8am - 12am",
      sunday: "8am - 12am",
    },
    storeDescription: "",
    vip: "",
    shop: "Shop",
    href: "morrisongardens",
    facebook: "",
    instagram: "",
    twitter: "",
  },

  {
    id: "2600",
    img: "/images/logo/cannabay.svg",
    alt: "Cannabay Logo",
    logoPinch: "/images/logo/cannabay.svg",
    exteriorStore: "/images/storeExterior/cannabayOutdoor.jpg",
    interiorStore: "/images/storeInterior/cannabayIndoor.jpg",
    exAlt: "Cannabay Store Exterior",
    inAlt: "Cannabay Store Interior",
    name: "CannaBay",
    address1: "1321 N Sherman St",
    address2: "Bay City, MI 48708",
    escapedUrlAddress: "1321+N+Sherman+St%2C+Bay+City%2C+MI+48708",
    lat: 43.60316,
    lng: -83.87733,
    phone: "(989) 402-9011",
    phoneLink: "tel:+1-989-402-9011",
    type: {
      Rec: "3031",
      Med: "",
    },
    hours: {
      monday: "8am - 8:45pm",
      tuesday: "8am - 8:45pm",
      wednesday: "8am - 8:45pm",
      thursday: "8am - 8:45pm",
      friday: "8am - 8:45pm",
      saturday: "8am - 8:45pm",
      sunday: "8am - 8:45pm",
    },
    storeDescription:
      "CannaBay is Bay City, the first Yuma shop in Michigan. With a relaxed customer-based environment, CannaBay’s cannabis shopping experience is specifically tailored to help treat every customer to the best of our abilities. We pride ourselves on providing 1-on-1 service to each customer that walks through our doors. Each product on our shelves is carefully chosen to make sure we have only top quality state tested products.  At CannaBay, our passionate budtenders strive to provide you with a satisfied experience and a welcoming atmosphere every single time — by the bay.",
    vip: "",
    shop: "Shop",
    href: "cannabay",
    facebook: "https://www.facebook.com/CannabayDispensary",
    instagram: "https://www.instagram.com/cannabay_dispensary",
    twitter: "https://twitter.com/Yuma_Way",
  },

  {
    id: "4371",
    img: "/images/logo/courtSide.svg",
    alt: "Court Side Gardens Logo",
    logoPinch: "/images/logo/courtSide.svg",
    exteriorStore: "/images/storeExterior/WoodbridgeOutdoor.jpg",
    interiorStore: "/images/storeInterior/courtsideoutsidecorner.jpg",
    exAlt: "Courtside Gardens Store Exterior",
    inAlt: "Courtside Gardens Store Interior",
    name: "Courtside Gardens",
    address1: "1321 Court St",
    address2: "Saginaw, MI 48602",
    escapedUrlAddress: "1321+Court+St%2C+Saginaw%2C+MI+48602",
    lat: 43.422459,
    lng: -83.971098,
    phone: "(989) 702-2027",
    phoneLink: "tel:+1-989-702-2027",
    type: {
      Rec: "4371",
      Med: "",
    },
    hours: {
      monday: "9:00am - 9:00pm",
      tuesday: "9:00am - 9:00pm",
      wednesday: "9:00am - 9:00pm",
      thursday: "9:00am - 9:00pm",
      friday: "9:00am - 9:00pm",
      saturday: "9:00am - 9:00pm",
      sunday: "9:00am - 9:00pm",
    },
    storeDescription:
      "Welcome to Courtside Gardens, Saginaw’s first deli style marijuana retailer! Operated by Yuma Way Cannabis Company, our Colorado headquartered company has been operating since 2016, and is proud to offer a vast selection of quality flower, concentrates, edibles, and more. Do you have questions? Visit our store and our knowledgeable and friendly staff is there to help you select the product to fit your needs. Traveling up to Bay City? We’ve got you covered at CannaBay. From the River to Bay - Yuma Way.",
    vip: "",
    shop: "Shop",
    href: "courtsidegardens",
    facebook: "",
    instagram: "",
    twitter: "",
  },
  {
    id: "4404",
    img: "/images/logo/urban.svg",
    alt: "Urban Gardens Logo",
    logoPinch: "/images/logo/urban.svg",
    exteriorStore: "/images/storeExterior/urbanGardensOutdoor.jpg",
    interiorStore: "/images/storeInterior/urbanGardensIndoor.jpg",
    exAlt: "Urban Gardens Store Exterior",
    inAlt: "Urban Gardens Store Interior",
    name: "Urban Gardens",
    address1: "617 Portage St",
    address2: "Kalamazoo, MI 49007",
    escapedUrlAddress: "617+Portage+Street,+Kalamazoo,+MI+49007",
    lat: 42.2855295,
    lng: -85.5770345,
    phone: "(269) 888-7088",

    phoneLink: "tel:+1-269-888-7088",
    type: {
      Rec: "4404",
      Med: "",
    },
    hours: {
      monday: "8:00am - 10:00pm",
      tuesday: "8:00am - 10:00pm",
      wednesday: "8:00am - 10:00pm",
      thursday: "8:00am - 10:00pm",
      friday: "8:00am - 10:00pm",
      saturday: "8:00am - 10:00pm",
      sunday: "8:00am - 10:00pm",
    },
    storeDescription:
      "Welcome to Urban Gardens, downtown Kalamazoo's number #1 deli-style cannabis store! Operated by Yuma Way Cannabis Company. Our Colorado-headquartered company has been operating since 2016, and is proud to offer a vast selection of quality flower, concentrates and extracts, edibles, vape pens, cannabis wellness products, and more at affordable prices! Our knowledgeable and personable staff will help you to navigate our sea of options. We carry Wyld Gummies, Wana, KIVA, Redbud Roots, AirPro, MKX, Jeeter and Breeze!",
    vip: "",
    shop: "Shop",
    href: "urbangardens",
    facebook: "",
    instagram: "",
    twitter: "https://twitter.com/Yuma_Way",
  },
  {
    id: "5125",
    img: "/images/logo/releafLogo.png",
    alt: "ReLeaf on Vine Logo",
    logoPinch: "/images/logo/releafLogo.png",
    exteriorStore: "/images/storeExterior/releafExterior.jpeg",
    interiorStore: "/images/storeExterior/releafInterior.jpeg",
    exAlt: "ReLeaf on Vine Store Exterior",
    inAlt: "ReLeaf on Vine Store Interior",
    name: "ReLeaf on Vine",
    address1: "2544 E Vineyard Ave",
    address2: "Oxnard, CA 93036",
    escapedUrlAddress: "2544+E+Vineyard+Ave,+Oxnard,+CA+93036",
    lat: 34.2330255,
    lng: -119.1717317,
    phone: "(805) 394-1234",
    phoneLink: "tel:+1-805-394-1234",
    type: {
      Rec: "5125",
      Med: "",
    },
    hours: {
      monday: "9:00am - 9:00pm",
      tuesday: "9:00am - 9:00pm",
      wednesday: "9:00am - 9:00pm",
      thursday: "9:00am - 9:00pm",
      friday: "9:00am - 9:00pm",
      saturday: "9:00am - 9:00pm",
      sunday: "9:00am - 9:00pm",
    },
    storeDescription: "Coming Soon",
    vip: "",
    shop: "Shop",
    href: "releafonvine",
    facebook: "",
    instagram: "",
    twitter: "https://twitter.com/Yuma_Way",
  },
  {
    id: "5487",
    img: "/images/logo/garfield.svg",
    alt: "Yuma Way NJ Logo",
    logoPinch: "/images/logo/garfield.svg",
    exteriorStore: "/images/storeExterior/riverGardensExterior.jpeg",
    interiorStore: "/images/storeInterior/yumaNJInterior.jpeg",
    exAlt: "Yuma Way NJ Store Exterior",
    inAlt: "Yuma Way NJ Store Interior",
    name: "Yuma Way NJ",
    address1: "517 River Dr Suite 3",
    address2: "Garfield, NJ 07026",
    escapedUrlAddress: "517+River+Dr+a,+Garfield,+NJ+07026",
    lat: 40.8756351,
    lng: -74.1148276,
    phone: "(973) 755-5420",
    phoneLink: "tel:+1-973-755-5420",
    type: {
      Rec: "5713",
      Med: "5487",
    },
    hours: {
      monday: "7:00am - 10:00pm",
      tuesday: "7:00am - 10:00pm",
      wednesday: "7:00am - 10:00pm",
      thursday: "7:00am - 10:00pm",
      friday: "7:00am - 10:00pm",
      saturday: "7:00am - 10:00pm",
      sunday: "12:00am - 5:00PM",
    },
    storeDescription:
      "New Recreational and Medical Marijuana Dispensary offers its pre-grand opening prices! Welcome to Yuma Way NJ cannabis Dispensary! We are located off River Drive next to the best NJ bar and restaurant - Tavern 517. We provide the highest quality cannabis flower and products to meet every preference and budget. We carry large grades of flower, extracts and concentrates, edibles, vape pens, and topicals. Our staff is very knowledgeable and can help create a personalized shopping experience and assist with cannabis education and consumption of various methods. We carry Kind Tree, Cookies, Gage, Valhalla, Legend, Tyson, Verano, Avexia, Simply Herb, Airo, Miss Grass, Fernway, Justice Grown & more! Medical hours 7:00AM-10:00PM Monday-Saturday and 12:00PM-5:00PM on Sunday.",
    vip: "",
    shop: "Shop",
    href: "yumawaynj",
    facebook: "",
    instagram: "",
    twitter: "https://twitter.com/Yuma_Way",
  },
  {
    id: "5720",
    img: "/images/logo/huntington.svg",
    alt: "Yuma Way WV Logo",
    logoPinch: "/images/logo/huntington.svg",
    exteriorStore: "/images/storeExterior/huntingtonOutdoor.jpg",
    interiorStore: "/images/storeInterior/huntingtonIndoor.jpg",
    exAlt: "Yuma Way WV Store Exterior",
    inAlt: "Yuma Way WV Store Interior",
    name: "Yuma Way WV",
    address1: "1338 3rd Avenue",
    address2: "Huntington, WV 25701",
    escapedUrlAddress: "1338+3rd+Avenue,+Huntington,+WV+25701",
    lat: 38.42387382041823,
    lng: -82.43626847116379,
    phone: "(681) 378-2848",
    phoneLink: "tel:+1-681-378-2848",
    type: {
      Med: "5720",
      Rec: "",
    },
    hours: {
      monday: "8:00am - 9:00pm",
      tuesday: "8:00am - 9:00pm",
      wednesday: "8:00am - 9:00pm",
      thursday: "8:00am - 9:00pm",
      friday: "8:00am - 9:00pm",
      saturday: "8:00am - 9:00pm",
      sunday: "8:00am - 9:00pm",
    },
    storeDescription:
      "New Medical ATC by Yuma Way offering pre-grand opening prices. Welcome to Yuma Way, a Medical Marijuana Dispensary! We are located off 3rd Ave next to Fazoli's Italian - 1338 3rd Avenue. We provide the highest quality cannabis flower and highest quality cannabis products to meet every preference and budget. We carry large grades of cannabis flower, extracts and concentrates, edibles, vape pens, and topicals. Our staff is very knowledgeable and can help create a personalized shopping experience and assist with cannabis education and consumption various methods. We carry Verano, Country Grown, Hillfire, Strane, Muse, Modern Flower, Essence, Savvy & more!",
    vip: "",
    shop: "Shop",
    href: "yumawaywv",
    facebook: "",
    instagram: "",
    twitter: "https://twitter.com/Yuma_Way",
  },
  {
    id: "6018",
    img: "/images/logo/sunnySide.svg",
    alt: "Yuma Way Sunnyside Gardens Logo",
    logoPinch: "/images/logo/sunnySide.svg",
    exteriorStore: "/images/storeExterior/SunnysideOutDoor.JPG",
    interiorStore: "/images/storeInterior/SunnysideIndoor.JPG",
    exAlt: "Yuma Way Sunnyside Gardens Store Exterior",
    inAlt: "Yuma Way Sunnyside Gardens Store Interior",
    name: "Sunnyside Gardens Dispensary",
    address1: "2885 W 44th Ave, ",
    address2: "Denver, CO 80211",
    escapedUrlAddress: "2885+West+44th+Avenue,+Denver,+CO+80211",
    lat: 39.77687,
    lng: -105.02355,
    phone: "(720) 549-6710",
    phoneLink: "tel:+1-720-549-6710",
    type: {
      Med: "",
      Rec: "6018",
    },
    hours: {
      monday: "11:00am - 7:00pm",
      tuesday: "11:00am - 7:00pm",
      wednesday: "11:00am - 7:00pm",
      thursday: "11:00am - 7:00pm",
      friday: "11:00am - 7:00pm",
      saturday: "11:00am - 7:00pm",
      sunday: "11:00am - 7:00pm",
    },
    storeDescription:
      "Welcome to Sunnyside Gardens Dispensary! We are located at 2885 West 44th Avenue, Denver, Colorado 80211. We provide the highest quality cannabis flower and highest quality cannabis products to meet every preference and budget. We carry large grades of cannabis flower, extracts and concentrates, edibles, vape pens, and topicals. Our staff is very knowledgeable and can help create a personalized shopping experience and assist with cannabis education and consumption various methods. We carry Verano, Country Grown, Hillfire, Strane, Muse, Modern Flower, Essence, Savvy & more!",
    vip: "",
    shop: "Shop",
    href: "yumaway-sunny-side-gardens",
    facebook: "",
    instagram: "",
    twitter: "https://twitter.com/Yuma_Way",
  },
];

export default storeJson;
